// console.log('window.location.protocol', window.location.protocol);

const handleBaseUrl = (local?: string, protocol?: string) => {
  let baseUrl = '';
  let dataUrl = '';

  switch (local) {
    case 'v2.adoba.studio':
      baseUrl = `https://api-dayi.adoba.studio`;
      dataUrl = `https://api.adobaro.com`;
      break;
    default:
      baseUrl = `${protocol}//dev.adoba.studio:7983`;
      dataUrl = `https://api.dev.adobaro.com`;
      // dataUrl = `http://172.25.0.11:9080`; // 使用本地服务器调试接口
      break;
  }

  return { baseUrl, dataUrl };
};

const CONFIG = {
  BASEURL: handleBaseUrl(window.location.host, window.location.protocol).baseUrl,
  DATAURL: handleBaseUrl(window.location.host, window.location.protocol).dataUrl,
};

export default CONFIG;
