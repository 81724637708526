import { axios3 } from '../utils/axios';
import {
  RoadListType,
  RoadPlatformType,
  UpdateStatusType,
  RoadMixProfileType,
  RoadUserInfoType,
  RoadUserPlatformType,
  RoadPlatformIsStoppedParamsType,
} from '@/stores/RoadStore/types';

export interface IListParams {
  page?: number;
  size?: number;
  sort_view?: 1 | -1;
  sort_fans?: 1 | -1;
  sort_adoba?: 1 | -1;
}

export interface IListResponse {
  nextPageToken: string;
  list: RoadListType[];
}

export interface IPlatformResponse {
  list: RoadPlatformType[];
}

export interface IUserPlatformResponse {
  list: RoadUserPlatformType[];
}

const roadServices = {
  // // 查询所有达人
  // getAll: () =>
  //   axios3.get<IListResponse, IListResponse>('studio/v1/self_operation/all_creators', {
  //     params: { page_size: 1000 },
  //   }),
  // // 查询申请中达人
  // getList: () =>
  //   axios3.get<IListResponse, IListResponse>('studio/v1/opening_platform/creators', {
  //     params: { page_size: 1000 },
  //   }),

  // 根据类型查询Road达人列表
  getRoadCreatorList: (status: string) =>
    axios3.get<IListResponse, IListResponse>('/studio/v1/users', {
      params: { status },
    }),

  // 搜索road达人
  searchCreator: (content: string) =>
    axios3.get<IListResponse, IListResponse>(`/studio/v1/user/${content}`),

  // 获取自运营达人的基本信息
  getBasicInfo: (uid: number) =>
    axios3.get<RoadUserInfoType, RoadUserInfoType>('/studio/v1/user/basic', {
      params: { uid },
    }),

  // 获取自运营达人认证资料
  getIdentity: (uid: number) =>
    axios3.get<RoadMixProfileType, RoadMixProfileType>('/studio/v1/user/identity', {
      params: { uid },
    }),
  // 用户申请开通平台的列表
  getPlatforms: (uid: number) =>
    axios3.get<IPlatformResponse, IPlatformResponse>(`/studio/v1/user/opening/platform/${uid}`),

  // 修改达人开通平台进度
  updateStatus: (params: UpdateStatusType) =>
    axios3.post('/studio/v1/user/opening/platform', params),
  // 用户开通完成平台的列表
  getUserPlatforms: (uid: number) =>
    axios3.get<IUserPlatformResponse, IUserPlatformResponse>(
      '/studio/v1/user/operating/platforms',
      {
        params: { uid },
      }
    ),
  // 修改某运营中的平台数据
  updateUserPlatforms: (params: RoadUserPlatformType) =>
    axios3.patch('/studio/v1/user/operating/platform', params),
  // 开关某运营中的平台数据爬取 isStopped 爬虫状态
  updateIsStopped: (params: RoadPlatformIsStoppedParamsType) =>
    axios3.patch('/studio/v1/user/operating/platform/stopping', params),
};
export default roadServices;
