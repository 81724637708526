import { axios3 } from '@/utils/axios';

export interface EnterpriseInfo {
  name: string;
  approvalTime: string;
  createdAt: string;
  operationStatus: string;
  email: string;
  paypalId: string;
}

export interface EnterpriseListItem {
  id: number;
  eid: number;
  email: string;
  name: string;
  operator: string;
  operationStatus: string;
  membersNum: number;
  approvalTime: string;
  createdAt: string;
}

export interface EnterpriseListParams {
  limit: number | undefined;
  offset: number | undefined;
  orders: string[] | undefined;
  eid?: number;
  licenseNo?: string;
  createStart?: string;
  createEnd?: string;
  registeredStart?: string;
  registeredEnd?: string;
}

export interface CreateEnterpriseParams {
  eid: number;
  operator: string;
  name: string;
}

export interface EnterpriseInfoParams {
  eid: string;
}

export interface EnterpriseRenewListParams {
  limit: number | undefined;
  offset: number | undefined;
  orders: string[] | undefined;
  eid: number;
}

export interface EnterpriseRenewList {
  id: number;
  eid: number;
  dues: Due;
  endTime: string; // 应付款日
  countdownTime: string; // 付款倒计时
  paidTime: string; // 实际支付时间
}

interface Due {
  currency: string;
  amount: number; // 金额（美元）
  people: number; // 人数
}

export interface PaidRenewParams {
  id: number;
  orderNo: string;
  paidTime: string;
}

const enterpriseServices = {
  // 获取企业信息列表
  getEnterpriseList: (params: EnterpriseListParams) => {
    return axios3.get('/studio/v2/user/enterprises', { params });
  },

  // 批准企业
  createEnterpriseInfo: (params: CreateEnterpriseParams) => {
    return axios3.put('/studio/v2/user/enterprise', params);
  },

  // 获取企业详情信息
  getEnterpriseInfo: (params: EnterpriseInfoParams) => {
    return axios3.get(`/studio/v2/user/enterprise_user/${params.eid}`);
  },

  // 获取企业账号的订阅列表
  getEnterpriseRenewList: (params: EnterpriseRenewListParams) => {
    return axios3.get('/studio/v2/order/e/subscriptions', { params });
  },

  // 确认企业订阅已支付
  confirmPaidRenew: (params: PaidRenewParams) => {
    return axios3.post('/studio/v2/order/e/subscription:paid', params);
  },
};

export default enterpriseServices;
