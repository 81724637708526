import auditConfigServices, {
  CreateNewTemplateParams,
  GetTemplatesListParams,
} from '@/services/auditConfigServices';
import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';

export default class AuditConfigStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'AuditConfigStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  // 获取模板列表
  async getTemplatesList(params: GetTemplatesListParams) {
    const res = await auditConfigServices.getTemplatesList(params);
    return Promise.resolve(res);
  }

  // 根据tid删除模板
  async deleteTemplate(tid: number) {
    const res = await auditConfigServices.deleteTemplate(tid);
    return Promise.resolve(res);
  }

  // 根据pid创建模板
  async createTemplate(params: CreateNewTemplateParams) {
    const res = await auditConfigServices.createTemplate(params);
    return Promise.resolve(res);
  }

  // 根据tid更新模板
  async updateTemplate(params: CreateNewTemplateParams) {
    const res = await auditConfigServices.updateTemplate(params);
    return Promise.resolve(res);
  }
}
