import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import creatorServices, { ICreatorFeedbackParams } from '@/services/creatorServices';

export default class FeedbackStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'FeedbackStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  async getFeedbackList(params: ICreatorFeedbackParams) {
    const res = await creatorServices.getCreatorFeedback(params);
    return Promise.resolve(res.data);
  }
}
