import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import translationServices, {
  IAITranslateParams,
  ITranslationListParams,
} from '@/services/translationServices';

export default class TranslationStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'TranslationStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  async srtTranslate(params: any) {
    const res = await translationServices.srtTranslate(params);
    return Promise.resolve(res);
  }

  async aiTranslate(params: IAITranslateParams) {
    const res = await translationServices.aiTranslate(params);
    return Promise.resolve(res);
  }

  translationList = [];
  async getTranslationList(params: ITranslationListParams) {
    const res = await translationServices.getTranslationList(params);
    return Promise.resolve(res);
  }
}
