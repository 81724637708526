import { MemberBenefitsList } from '@/pages/MemberBenefits/constants';
import { axios3 } from '@/utils/axios';

export interface MemberListParams {
  limit: number | undefined;
  offset: number | undefined;
  orders: string[] | undefined;
  isSearch: boolean;
  uid?: number;
  nickname?: string;
  createdStartAt?: string;
  createdEndAt?: string;
}

interface LevelGiftParams {
  level: string;
  giftMonths: number;
  remark: string;
  reviewer: string;
}

export interface CreateMemberParams {
  uids: number[];
  levelGift: LevelGiftParams;
}

interface GetMemberList {
  list: MemberBenefitsList[];
  totals: number;
}

const memberServices = {
  // 获取会员权益列表
  getMemberList: (params: MemberListParams) => {
    return axios3.get<GetMemberList, GetMemberList>('/studio/v2/order/level_gifts', { params });
  },

  // 创建新会员权益
  createNewMember: (params: CreateMemberParams) => {
    return axios3.post('/studio/v2/order/level_gifts', params);
  },
};

export default memberServices;
