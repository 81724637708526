import React, { Suspense, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { useRoutes } from 'react-router-dom';
import routes from './routers';
import Loading from '@/components/Loading/loading';
import { inject, observer } from 'mobx-react';
import LanguageStore from './stores/LanguageStore';
import './i18n/config'; // 引用配置文件
import './App.less';
import en from 'antd/es/locale/en_US';
import zh from 'antd/es/locale/zh_CN';
import ko from 'antd/es/locale/ko_KR';
import ja from 'antd/es/locale/ja_JP';

interface IProps {
  language?: LanguageStore;
}

const App: React.FC<IProps> = (props) => {
  const { language } = props;
  const routing = useRoutes(routes);
  const [locale, setLocale] = useState<any>(en);

  useEffect(() => {
    changeLocale(language?.language);
  }, [language?.language]);

  const changeLocale = (language: string = 'en') => {
    switch (language) {
      case 'en':
        setLocale(en);
        break;
      case 'zh':
        setLocale(zh);
        break;
      case 'kr':
        setLocale(ko);
        break;
      case 'jp':
        setLocale(ja);
        break;
    }
  };

  return (
    <ConfigProvider locale={locale}>
      <Suspense fallback={<Loading />}>{routing}</Suspense>
    </ConfigProvider>
  );
};

export default inject('language')(observer(App));
