import { axios, axios3 } from '../utils/axios';
import {
  AddCreatorAccountParams,
  UserParamsType,
  CreatorListType,
  CreatorType,
  CreatorMcnInfoType,
  CreatorPassportType,
  CreatorForeignPlatform,
  CreatorContractType,
  CreatorPlatformType,
  CreatorBusinessType,
  CreatorPlatformDataType,
  CategoriesType,
  OperationLogType,
} from '@/stores/AdobaroStore/types';
import { CreatorFeedbackType } from '@/stores/FeedbackStore/types';

export interface ISearchCreatorResponse {
  data: CreatorType[];
  msg: string;
  status: number;
}

export interface ICreatorListResponse {
  count: number;
  list: CreatorListType[];
}

export interface IGetCreatorBaseInfoResponse {
  data: CreatorType;
}

export interface ICheckCreatorBaseInfoParams {
  phone?: string;
  nickname?: string;
  adoba_id?: number;
}

export interface ICheckCreatorBaseInfoResponse {
  data: string;
  msg?: string;
  status: number;
}

export interface IMcnInfoResponse {
  invitation: CreatorMcnInfoType;
}

export interface IForeignPlatformResponse {
  foreignPlatform: CreatorForeignPlatform;
}

export interface IGetContractListResponse {
  list?: CreatorContractType[];
}

export interface IPlatformListResponse {
  list: CreatorPlatformType[];
}

export interface IGetPlatformsDataResponse {
  data: {
    platform_data?: CreatorPlatformDataType[];
  };
  msg?: string;
  status?: number;
}

export interface IGetBusinessInfoResponse {
  list?: CreatorBusinessType[];
}

export interface IGetCategoriesParams {
  page: number;
  size: number;
}

export interface IGetCategoriesResponse {
  data: CategoriesType[];
  msg?: string;
  status?: number;
}

export interface IGetUserOperateLogsParams {
  page: number;
  size: number;
  uid?: number;
}

export interface IGetOperateLogsResponse {
  status: number;
  msg: string;
  data: OperationLogType[];
}

export interface ICreatorFeedbackParams {
  type: number;
  page: number;
  size: number;
}

interface ICreatorFeedbackResponse {
  data: {
    list: CreatorFeedbackType[];
    total_num: number;
  };
  msg: string;
  status: number;
}

const adobaroServices = {
  // 添加新达人账号
  addCreatorAccount: (params: AddCreatorAccountParams) => axios3.post('/studio/v2/user', params),

  searchCreator: (name: string) =>
    axios.get<ISearchCreatorResponse, ISearchCreatorResponse>(`/api/creator/search/${name}`),

  creatorList: (params?: UserParamsType) =>
    axios3.get<ICreatorListResponse, ICreatorListResponse>('/studio/v2/users', { params }),

  // 获取达人所属MCN信息
  getMCNInfo: (uid?: number) =>
    axios3.get<IMcnInfoResponse, IMcnInfoResponse>('studio/v2/user/invitation', {
      params: { uid: uid },
    }),

  // 获取达人的基本信息
  getCreatorBaseInfo: (uid?: number) =>
    axios3.get<CreatorType, CreatorType>(`/studio/v2/user`, { params: { uid } }),

  // 检测达人的基本信息是否重复
  checkCreatorBaseInfo: (params: ICheckCreatorBaseInfoParams) =>
    axios.get<ICheckCreatorBaseInfoResponse, ICheckCreatorBaseInfoResponse>('/api/creator/exist', {
      params,
    }),

  // 修改达人的基本信息
  updateCreatorBaseInfo: (params?: CreatorType) => axios3.patch(`/studio/v2/user`, params),

  //获取达人的护照信息
  getCreatorPassportInfo: (uid?: number) =>
    axios3.get<CreatorPassportType, CreatorPassportType>('/studio/v2/user/passport', {
      params: { uid },
    }),

  // 修改达人的护照信息
  changePassportInfo: (params: CreatorPassportType) =>
    axios3.put('/studio/v2/user/passport_info', params),

  //获取达人的海外平台信息
  getForeignPlatform: (uid?: number) =>
    axios3.get<IForeignPlatformResponse, IForeignPlatformResponse>(
      'studio/v2/user/foreign_platform_ai',
      { params: { uid: uid } }
    ),

  //修改达人的海外平台信息
  changeForeignPlatform: (params?: CreatorForeignPlatform) =>
    axios3.patch('studio/v2/user/foreign_platform_ai', { params }),

  // 获取达人的合同信息
  getContractList: (uid: number) =>
    axios3.get<IGetContractListResponse, IGetContractListResponse>(
      `/studio/v2/user/contracts/${uid}`
    ),

  // 新增达人合同信息
  addContractInfo: (params: CreatorContractType) =>
    axios3.post<CreatorContractType, CreatorContractType>('/studio/v2/user/contract', params),

  // 修改达人的合同信息
  updateContractInfo: (params: CreatorContractType) =>
    axios3.patch<CreatorContractType, CreatorContractType>(
      `/studio/v2/user/contract/${params.id}`,
      params
    ),

  //获取达人运营平台列表
  getOperationPlatformList: (uid?: number) =>
    axios3.get<IPlatformListResponse, IPlatformListResponse>('studio/v2/user/operating_platforms', {
      params: { uid },
    }),

  // 查询达人各平台的粉丝数据
  getCreatorPlatformsData: (uid: number) =>
    axios3.get<IGetPlatformsDataResponse, IGetPlatformsDataResponse>(
      `/studio/v1/spider/incr_metric/${uid}`
    ),

  // 新增|修改运营平台
  updateOperationPlatform: (params: CreatorPlatformType) =>
    axios3.patch('/studio/v2/user/operating_platform', params),

  //获取达人商务信息
  getBusinessInfo: (uid: number) =>
    axios3.get<IGetBusinessInfoResponse, IGetBusinessInfoResponse>(
      '/studio/v2/user/platform/ad_incomes',
      { params: { uid } }
    ),

  addBusinessInfo: (params: CreatorBusinessType) =>
    axios3.post('/studio/v2/user/platform/ad_income', params),

  updateBusinessInfo: (params: CreatorBusinessType) =>
    axios3.patch('/studio/v2/user/platform/ad_income', params),

  deleteBusinessInfo: (id: number) => axios3.delete(`/studio/v2/user/platform/ad_incomes/${id}`),

  getCategories: (params: IGetCategoriesParams) =>
    axios.get<IGetCategoriesResponse, IGetCategoriesResponse>('/api/creator/categories', {
      params,
    }),

  // 查询用户日志
  getUserOperateLogs: (params: IGetUserOperateLogsParams) =>
    axios.get<IGetOperateLogsResponse, IGetOperateLogsResponse>('/api/user/op_history', { params }),

  // 获取达人建议(from adoba Road)
  getCreatorFeedback: (params: ICreatorFeedbackParams) =>
    axios.get<ICreatorFeedbackResponse, ICreatorFeedbackResponse>('/api/creator/suggestions', {
      params,
    }),
};

export default adobaroServices;
