import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import commonServices from '@/services/commonServices';
import {
  CountryType,
  PlatformType,
  UserGroupType,
  BusinessType,
  ActivePlatformType,
  NotificationType,
  PlatformsListType,
  CountryListType,
} from './types';

export default class CommonStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'CommonStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  countryList: CountryType[] = [];
  countriesList: CountryListType[] = [];
  userGroup: UserGroupType[] = [];
  businessList: BusinessType[] = [];
  foreignPlatformsList: PlatformsListType[] = [];
  async getResources() {
    const res = await commonServices.resources();
    if (res.status === 0) {
      this.countryList = res.data.country_list;
      this.userGroup = res.data.user_group;
      // this.businessList = res.data.business_list;
      this.businessList = this.handleBusinessListLang(res.data.business_list);
      // this.foreignPlatformsList = res.data.foreign_platform_list;
      const foreignPlatforms = res.data.foreign_platform_list?.map((prop) => {
        const foreignPlatform = this.platformList.find((item) => item?.id === prop?.id);
        return (
          foreignPlatform || {
            id: prop.id,
            name: prop.name,
            icon: require('@/assets/platform_icons/empty.png'),
          }
        );
      });

      this.foreignPlatformsList = foreignPlatforms || [];
      /* const platforms = res.data.operating_platform_list.map((prop) => {
        const platform = this.platformList.find((item) => item.id === prop?.id);
        console.log('prop', prop);
        return {
          id: prop.id,
          name: prop.name || '未知',
          icon: platform?.icon || require('@/assets/platform_icons/empty.png'),
          cn: res.data.operating_platform_list[prop.id]?.name,
          // cn: platform?.name,
          // en: platform?.en || platform?.name,
          // jp: platform?.jp || platform?.name,
          // kr: platform?.kr || platform?.name,
        };
      }); */
      const platforms = res.data.operating_platform_list.map((prop) => {
        const platform = this.platformList.find((item) => item.id === prop.id);
        return (
          platform || {
            id: prop.id,
            name: prop.name,
            icon: require('@/assets/platform_icons/empty.png'),
          }
        );
      });

      this.platformList = platforms || [];
    }
    return Promise.resolve(res.data);
  }
  async getCountries() {
    const res = await commonServices.getCountries();
    this.countriesList = res.list;
    return Promise.resolve(res.list);
  }


  activePlatforms: ActivePlatformType[] = [];
  async getActivePlatform() {
    const res = await commonServices.activePlatform();
    if (res.status === 0) {
      this.activePlatforms = res.data;
    }
    return Promise.resolve(res.data);
  }

  notificationCount = 0;
  notifications: NotificationType[] = [];
  async getNotifications() {
    const res = await commonServices.notifications();
    if (res.status === 0) {
      this.notificationCount = res.data.count;
      this.notifications = res.data.items;
    }
    return Promise.resolve(res.data);
  }

  getCountryName(code?: string, language: 'cn' | 'en' | 'kr' = 'cn') {
    const country = this.countryList.find((c) => c.code === code);
    return country ? country[language] : '未知';
  }

  getCountriesName(id?: number, language: 'cn' | 'en' | 'kr' = 'cn') {
    const country = this.countriesList.find((c) => c.id === id);
    const lan = language === 'cn' ? 'zh' : language;
    return country ? country[lan] : '未知';
  }

  getCountryCode(name?: string, language: 'cn' | 'en' | 'kr' = 'cn') {
    const country = this.countryList.find((c) => c[language] === name);
    return country ? country.code : '';
  }

  platformList: PlatformType[] = [
    { id: 1, name: 'platform_hao_kan', icon: require('@/assets/platform_icons/haokan.png') },
    { id: 2, name: 'platform_tencent', icon: require('@/assets/platform_icons/tencent.png') },
    { id: 3, name: 'platform_iqiyi', icon: require('@/assets/platform_icons/i_qi_yi.png') },
    { id: 4, name: 'platform_bilibili', icon: require('@/assets/platform_icons/bili.png') },
    { id: 5, name: 'platform_xi_gua', icon: require('@/assets/platform_icons/xigua.png') },
    { id: 6, name: 'platform_wei_bo', icon: require('@/assets/platform_icons/weibo.jpg') },
    { id: 7, name: 'platform_youtube', icon: require('@/assets/platform_icons/youtube.jpg') },
    { id: 8, name: 'platform_dou_yin', icon: require('@/assets/platform_icons/douyin.png') },
    { id: 9, name: 'platform_red_book', icon: require('@/assets/platform_icons/red_book.jpg') },
    { id: 10, name: 'platform_kuai_shou', icon: require('@/assets/platform_icons/kwai.jpg') },
    {
      id: 11,
      name: 'platform_cloud_music',
      icon: require('@/assets/platform_icons/net_music.png'),
    },
    { id: 12, name: 'platform_wechat_video', icon: require('@/assets/platform_icons/shipin.jpg') },
    { id: 13, name: 'platform_mei_pai', icon: require('@/assets/platform_icons/meipai.jpg') },
    { id: 14, name: 'platform_da_yu', icon: require('@/assets/platform_icons/dayu.jpg') },
    { id: 15, name: 'platform_dian_tao', icon: require('@/assets/platform_icons/diantao.png') },
    { id: 16, name: 'platform_qq_world', icon: require('@/assets/platform_icons/qq_world.png') },
    { id: 99, name: 'platform_youtube', icon: require('@/assets/platform_icons/youtube.png') },
    { id: 100, name: 'platform_tiktok', icon: require('@/assets/platform_icons/tiktok.png') },
    { id: 101, name: 'platform_instagram', icon: require('@/assets/platform_icons/instagram.png') },
    { id: 102, name: 'platform_twitter', icon: require('@/assets/platform_icons/twitter.png') },
  ];

  // getPlatformName(id?: number | string, language: 'cn' | 'en' | 'kr' | 'jp' = 'cn') {
  //   const platform = this.platformList.find((p) => p.id === Number(id));
  //   // return platform?.name || '未知';
  //   return platform ? platform[language] : '未知';
  // }

  getPlatformName(id?: number | string) {
    const platform = this.platformList.find((p) => p.id === Number(id));
    return platform?.name || id;
  }

  getPlatformIcon(id?: number | string) {
    const platform = this.platformList.find((p) => p.id === Number(id));
    return platform?.icon || require('@/assets/platform_icons/empty.png');
  }

  getForeignPlatformName(id?: number | string) {
    const foreignPlatform = this.foreignPlatformsList.find((p) => p.id === Number(id));
    return foreignPlatform?.name || id;
  }

  getForeignPlatformIcon(id?: number | string) {
    const foreignPlatform = this.foreignPlatformsList.find((p) => p.id === Number(id));
    return foreignPlatform?.icon || require('@/assets/platform_icons/empty.png');
  }

  async getOssPolicy() {
    const res = await commonServices.getOssPolicy();
    return Promise.resolve(res.data);
  }

  handleBusinessListLang(data: BusinessType[]) {
    let newList: BusinessType[] = [];
    if (data) {
      newList = data?.map((item) => {
        if (item.id === 17) {
          item.name = 'platform_tao_bao';
        }
        if (item.id === 18) {
          item.name = 'platform_JD';
        }
        if (item.id === 19) {
          item.name = 'platform_pin_duo_duo';
        }
        if (item.id === 20) {
          item.name = 'platform_mo_gu';
        }
        return item;
      });
    }
    return newList;
  }

  getBusinessName(id?: number | string) {
    const business = this.businessList.find((p) => p.id === Number(id));
    return business?.name || id;
  }
}
