import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_en from './en';
import translation_zh from './zh';
import translation_kr from './kr';
import translation_jp from './jp';

const resources = {
  en: {
    translation: translation_en,
  },
  zh: {
    translation: translation_zh,
  },
  kr: {
    translation: translation_kr,
  },
  jp: {
    translation: translation_jp,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: JSON.parse(localStorage.getItem('LanguageStore')!)?.language || 'en', // 默认语言
  interpolation: {
    escapeValue: false,
  },
});

// Add fallback for brackets variables in the translation key (e.g {0})
const replaceWithParams = (text: string, params: any[]) => {
  let result = text;
  params.forEach((value, index) => {
    const reg = new RegExp(`({)${index}(})`, 'g');
    result = result.replace(reg, value);
  });
  return result;
};

export function tf(key: string, ...params: any[]) {
  return replaceWithParams(i18n.t(key), params);
}

export default i18n;
