import { axios } from '../utils/axios';
import { CreatorType } from '@/stores/AccountStore/types';

export interface ITotalResponse {
  status: number;
  msg: string;
  data: {
    creators: number;
    new_views: number;
  };
}

export interface IListParams {
  page?: number;
  size?: number;
  sort_view?: 1 | -1;
  sort_fans?: 1 | -1;
  sort_adoba?: 1 | -1;
}

export interface IListResponse {
  status: number;
  msg: string;
  data: {
    total_num: number;
    list: CreatorType[];
  }
}

const dashboardServices = {
  total: () => axios.get<ITotalResponse, ITotalResponse>('/api/dashboard/total'),
  list: (params: IListParams) => axios.post<IListResponse, IListResponse>('/api/dashboard/list', params),
}

export default dashboardServices;