import { axios, axios3 } from '../utils/axios';
import {
  CountryType,
  UserGroupType,
  ActivePlatformType,
  NotificationType,
  BusinessType,
  PlatformsListType,
  CountryListType
} from '@/stores/CommonStore/types';

export interface INotificationsResponse {
  data: {
    count: number;
    items: NotificationType[];
  };
  msg: string;
  status: number;
}

export interface IResourceResponse {
  data: {
    country_list: CountryType[];
    operating_platform_list: PlatformsListType[];
    foreign_platform_list: PlatformsListType[];
    user_group: UserGroupType[];
    business_list: BusinessType[];
  };
  msg: string;
  status: number;
}

export interface IActivePlatformResponse {
  data: ActivePlatformType[];
  msg: string;
  status: number;
}

export interface IGetCountriesResponse {
 list: CountryListType[];
}

const commonServices = {
  resources: () => axios.get<IResourceResponse, IResourceResponse>('/api/resource'),
  getCountries: () => axios3.get<IGetCountriesResponse, IGetCountriesResponse>('/studio/v1/countries'),
  activePlatform: () =>
    axios.get<IActivePlatformResponse, IActivePlatformResponse>('/api/active/platform'),
  notifications: () =>
    axios.get<INotificationsResponse, INotificationsResponse>('/api/headline-notifications'),
  getOssPolicy: () => axios.get('/api/ali/policy'),
};

export default commonServices;
