import UserStore from './UserStore';
import CommonStore from './CommonStore';
import WorkBenchStore from './WorkBenchStore';
import AccountStore from './AccountStore';
import AdobaroStore from './AdobaroStore';
import LanguageStore from './LanguageStore';
import FeedbackStore from './FeedbackStore';
import RoadStore from './RoadStore';
import IncomeManage from './IncomeStore';
import NoticeStore from './NoticeStore';
import TranslationStore from './TranslationStore';
import MemberStore from './MemberStore';
import AuditConfigStore from './AuditConfigStore';
import EnterpriseStore from './EnterpriseStore';

const stores = {
  user: new UserStore(),
  common: new CommonStore(),
  workBench: new WorkBenchStore(),
  account: new AccountStore(),
  adobaro: new AdobaroStore(),
  language: new LanguageStore(),
  feedback: new FeedbackStore(),
  road: new RoadStore(),
  income: new IncomeManage(),
  notice: new NoticeStore(),
  translation: new TranslationStore(),
  member: new MemberStore(),
  audit: new AuditConfigStore(),
  enterprise: new EnterpriseStore(),
};

export default stores;
