import { makeAutoObservable, action, runInAction } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import userServices from '@/services/userServices';
import { LoginType } from '@/pages/Login/types';
import { RolesType, UserType } from './types';

export default class UserStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'UserStore',
      properties: ['token'],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  token: string = ''; // token
  setToken(val: string) {
    this.token = val;
  }

  // 登录
  async login(params: LoginType) {
    const res = await userServices.login(params);
    return Promise.resolve(res);
  }

  // 扫码登录
  async loginWithCode(code: string, url: string) {
    const res = await userServices.loginWithCode(code, url);
    return Promise.resolve(res);
  }

  userRoles: RolesType[] = [];
  // 获取user info
  async getUser() {
    const res = await userServices.getUser();
    if (res.status === 0) {
      this.saveUser(res.data.user);
      runInAction(() => {
        this.userRoles = res.data.roles;
      });
    }
    return Promise.resolve(res.data);
  }

  account: string = '';
  // auth_level: number = 0;
  create_time: string = '';
  group_id: number = 0;
  id: number = 0;
  last_ip: string = '';
  last_login: string = '';
  name: string = '';
  avatar: string = '';
  sex: number = 0;
  status: number = 0;
  update_time: string = '';
  saveUser(user: UserType) {
    this.account = user.account;
    // this.auth_level = user.auth_level;
    this.create_time = user.create_time;
    this.group_id = user.group_id;
    this.id = user.id;
    this.last_ip = user.last_ip;
    this.last_login = user.last_login;
    this.name = user.name;
    this.avatar = user.avatar;
    this.sex = user.sex;
    this.status = user.status;
    this.update_time = user.update_time;
  }

  isLogin: boolean = false;
  setIsLogin(loginStatus: boolean) {
    this.isLogin = loginStatus;
  }
}
