import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from '@/assets/lottie/loading-dots.json';
import styles from './index.module.less';

// 切换页面时的加载动画
const LoadingScreen: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={styles.box}>
      <div className={styles.content}>
        <Lottie {...defaultOptions} className={styles.lottie} />
        <p className={styles.tip}>Loading...</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
