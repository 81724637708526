import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import {
  NoticeDataType,
  // GetNotificationParamType,
  PushNoticeType,
  PushMessageType,
  PushNotificationParamType,
  TranslateNoticeParamType,
  GetReceiverParamType,
  SenderType
} from './types';
import noticeServices from '@/services/noticeServices';

export default class NoticeStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'NoticeStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  noticeList: NoticeDataType[] = [];
  async getNotification() {
    this.noticeList = [];
    const res = await noticeServices.getNotification();
    this.setNoticeList(res.list);
    return Promise.resolve(res);
  }
  
  setNoticeList(list: NoticeDataType[]) {
    this.noticeList = list;
  }

  async pushNotification(
    pushNotification: PushNoticeType,
    pushMessages: PushMessageType[]
  ) {
    const params: PushNotificationParamType = {
      pushNotification,
      pushMessages,
    };
    const res = await noticeServices.pushNotification(params);
    return Promise.resolve(res);
  }

  async translateNotice(params: TranslateNoticeParamType) {
    const res = await noticeServices.translateNotice(params);
    return Promise.resolve(res);
  }

  userList: SenderType[] = [];
  async getReceiverList(params?: GetReceiverParamType) {
    const list:SenderType[] = [];
    this.userList = [];
    const res = await noticeServices.getReceiverList(params);
    res.list.forEach(item => {
      list.push({ id: item.user.uid, avatar: item.user.avatar, nickname: item.user.nickname });
    });
    this.setUserList(list);
    return Promise.resolve(res);
  }

  setUserList(list: SenderType[]) {
    this.userList = list;
  }

  // 清除Store数据
  clearStore() {
    // this.noticeList = [];
  }
}
