import { axios, axios3 } from '../utils/axios';
import {
  CreatorType,
  /* CreatorDetailType, */
  BusinessInfoType,
  CategoriesType,
  CreatorPlatformDataType,
  AddAccountParamsType,
  AccountCreatorType,
  CreatorPrivacyDataType,
  SeriesFansListItemType,
} from '@/stores/AccountStore/types';
import { CreatorFeedbackType } from '@/stores/FeedbackStore/types';

export interface IAddCreatorResponse {
  data: {
    creator_id: number;
  };
  msg: string;
  status: number;
}

export interface ISearchCreatorResponse {
  data: CreatorType[];
  msg: string;
  status: number;
}

export interface ICreatorListResponse {
  data: {
    total_num: number;
    list: CreatorType[];
  };
  msg: string;
  status: number;
}

/* export interface ICreatorDetailResponse {
  data: CreatorDetailType;
  msg: string;
  status: number;
} */

export interface IGetBusinessInfoResponse {
  data?: BusinessInfoType[];
  msg?: string;
  status?: number;
}

export interface IAddBusinessInfoResponse {
  data: null;
  msg?: string;
  status?: number;
}

export interface IGetCategoriesResponse {
  data: CategoriesType[];
  msg?: string;
  status?: number;
}

export interface IGetCategoriesParams {
  page: number;
  size: number;
}

export interface IGetCreatorPlatformDataResponse {
  data: {
    platform_data?: CreatorPlatformDataType[];
  };
  msg?: string;
  status?: number;
}

// 获取达人各平台的数据
export interface IGetCreatorPlatformsDataResponse {
  times: string[];
  series: SeriesFansListItemType[];
}

export interface IGetCreatorPrivacyDataResponse {
  data: CreatorPrivacyDataType;
  msg?: string;
  status?: number;
}

export interface IGetCreatorBaseInfoResponse {
  data: CreatorType;
  msg?: string;
  status: number;
}

export interface ICheckCreatorBaseInfoParams {
  phone?: string;
  nickname?: string;
  adoba_id?: number;
}

export interface ICheckCreatorBaseInfoResponse {
  data: string;
  msg?: string;
  status: number;
}

export interface ICreatorFeedbackParams {
  type: number;
  page: number;
  size: number;
}

interface ICreatorFeedbackResponse {
  data: {
    list: CreatorFeedbackType[];
    total_num: number;
  };
  msg: string;
  status: number;
}

const creatorServices = {
  addCreator: (params: any) =>
    axios.post<IAddCreatorResponse, IAddCreatorResponse>('/api/creator/add', params),

  searchCreator: (name: string) =>
    axios.get<ISearchCreatorResponse, ISearchCreatorResponse>(`/api/creator/search/${name}`),

  creatorList: () => axios.get<ICreatorListResponse, ICreatorListResponse>('/api/creator/list'),

  // 该接口已废弃，需要删除相关调用；达人相关详细信息，在后端添加字段后，从creatorList中获取
  // creatorDetail: (id: number) =>
  //   axios.get<ICreatorDetailResponse, ICreatorDetailResponse>(`/api/creator/v2/${id}`),

  getBusinessInfo: (id: number) =>
    axios.get<IGetBusinessInfoResponse, IGetBusinessInfoResponse>(`/api/creator/ad_income/${id}`),

  addBusinessInfo: (params: BusinessInfoType) =>
    axios.post<IAddBusinessInfoResponse, IAddBusinessInfoResponse>(
      '/api/creator/ad_income',
      params
    ),

  updateBusinessInfo: (params: BusinessInfoType) =>
    axios.put<IAddBusinessInfoResponse, IAddBusinessInfoResponse>('/api/creator/ad_income', params),

  deleteBusinessInfo: (id: number) =>
    axios.delete<IAddBusinessInfoResponse, IAddBusinessInfoResponse>(
      `/api/creator/ad_income/${id}`
    ),

  getCategories: (params: IGetCategoriesParams) =>
    axios.get<IGetCategoriesResponse, IGetCategoriesResponse>('/api/creator/categories', {
      params,
    }),

  // 该接口已废弃
  // getCreatorPlatformData: (id: number) =>
  //   axios.get<IGetCreatorPlatformDataResponse, IGetCreatorPlatformDataResponse>(
  //     `/api/creator/dashboard/detail/${id}`
  //   ),

  // 查询达人各平台的粉丝数据
  getCreatorPlatformsData: (uid: number) =>
    axios3.get<IGetCreatorPlatformsDataResponse, IGetCreatorPlatformsDataResponse>(
      `/studio/v1/spider/incr_metric/${uid}`
    ),

  // 检测达人的基本信息是否重复
  checkCreatorBaseInfo: (params: ICheckCreatorBaseInfoParams) =>
    axios.get<ICheckCreatorBaseInfoResponse, ICheckCreatorBaseInfoResponse>('/api/creator/exist', {
      params,
    }),

  // 创建达人的基本信息
  addCreatorBaseInfo: (params: AccountCreatorType) =>
    axios.put<IAddCreatorResponse, IAddCreatorResponse>('/api/creator/v2', params),

  // 创建达人的隐私信息
  addCreatorPrivacyInfo: (params: AddAccountParamsType) =>
    axios.put<IAddCreatorResponse, IAddCreatorResponse>('/api/creator/v2/privacy', params),

  // 获取达人的隐私信息
  getCreatorPrivacyData: (id?: number) =>
    axios.get<IGetCreatorPrivacyDataResponse, IGetCreatorPrivacyDataResponse>(
      `/api/creator/v2/privacy/${id}`
    ),

  // 获取达人的基本信息
  getCreatorBaseInfoData: (id: number) =>
    axios.get<IGetCreatorBaseInfoResponse, IGetCreatorBaseInfoResponse>(`/api/creator/${id}`),

  // 获取达人建议(from adoba Road)
  getCreatorFeedback: (params: ICreatorFeedbackParams) =>
    axios.get<ICreatorFeedbackResponse, ICreatorFeedbackResponse>('/api/creator/suggestions', {
      params,
    }),
};
export default creatorServices;
