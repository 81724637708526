import { axios3 } from '@/utils/axios';

export interface GetTemplatesListParams {
  // lang: string;
  kid: string;
}

export interface CreateNewTemplateParams {
  id?: number;
  pid: number;
  kid: string;
  labels: NewTemplateContent[];
  zh?: string;
  ko?: string;
  en?: string;
  children?: CreateNewTemplateParams[];
}

export interface getAllTemplate {
  list: CreateNewTemplateParams[];
}

interface NewTemplateContent {
  label: string;
  lang: string;
}

const auditConfigServices = {
  // 获取模板列表
  getTemplatesList: (params: GetTemplatesListParams) => {
    return axios3.get<getAllTemplate, getAllTemplate>('/studio/v2/template/all', { params });
  },

  // 根据tid删除模板
  deleteTemplate: (tid: number) => {
    return axios3.delete(`/studio/v2/template/${tid}`);
  },

  // 根据pid创建模板
  createTemplate: (params: CreateNewTemplateParams) => {
    return axios3.post('/studio/v2/template', params);
  },

  // 根据tid更新模板
  updateTemplate: (params: CreateNewTemplateParams) => {
    return axios3.put('/studio/v2/template', params);
  },
};

export default auditConfigServices;
