import { axios3, axios5 } from '../utils/axios';
import {
  NoticeDataType,
  PushNotificationParamType,
  TranslateNoticeParamType,
  GetReceiverParamType,
  ReceiverListType,

} from '@/stores/NoticeStore/types';

export interface IListParams {
  page?: number;
  size?: number;
  sort_view?: 1 | -1;
  sort_fans?: 1 | -1;
  sort_adoba?: 1 | -1;
}
export interface IListResponse {
  list: NoticeDataType[];
  count: number;
}

export interface IReceiverList{
  list: ReceiverListType[],
  count: number;
}

export interface ITranslateResult {
  source: string;
  code: string;
  target: string;
}
export interface ITtranslateResultList {
  items: ITranslateResult[]
}
const noticeServices = {
  pushNotification: (params: PushNotificationParamType) => 
    axios3.post('/studio/v2/courier/push_notification', params),//发送通知
  
  getNotification: () => 
    axios3.get<IListResponse, IListResponse>('/studio/v2/courier/push_notification'),//获取通知记录

  translateNotice: (params: TranslateNoticeParamType) =>
    axios5.post<ITtranslateResultList, ITtranslateResultList>('/ai/translate',  params ),//ai翻译
  
  getReceiverList: (params?: GetReceiverParamType) => 
    axios3.get<IReceiverList, IReceiverList>('/studio/v2/users', {params}),//获取接收者
};
export default noticeServices;
