import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import roadServices from '@/services/roadServices';
import {
  RoadUserInfoType,
  RoadMixProfileType,
  RoadPlatformType,
  UpdateStatusType,
  RoadUserPlatformType,
  RoadPlatformIsStoppedParamsType,
} from './types';

export default class RoadStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'RoadStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  // async getAll() {
  //   const res = await roadServices.getAll();
  //   return Promise.resolve(res.list);
  // }

  // async getList() {
  //   const res = await roadServices.getList();
  //   return Promise.resolve(res.list);
  // }

  // 根据类型查询达人列表
  async getRoadCreatorList(status: string) {
    const res = await roadServices.getRoadCreatorList(status);
    return Promise.resolve(res.list);
  }

  async searchCreator(key: string) {
    const res = await roadServices.searchCreator(key);
    return Promise.resolve(res.list);
  }

  uid: number = 0;
  setUid(uid: number) {
    this.uid = uid;
  }

  basicInfo: RoadUserInfoType = {
    email: '',
    nickname: '',
    phone: '',
    realName: '',
  };
  async getBasicInfo(uid: number) {
    const res = await roadServices.getBasicInfo(uid);
    this.setBasicInfo(res);
    return Promise.resolve(res);
  }

  setBasicInfo(res: RoadUserInfoType) {
    this.basicInfo = res;
  }

  identityInfo: RoadMixProfileType = {
    foreignPlatforms: [],
  };
  async getIdentity(uid: number) {
    const res = await roadServices.getIdentity(uid);
    this.setIdentityInfo(res);
    return Promise.resolve(res);
  }

  setIdentityInfo(res: RoadMixProfileType) {
    this.identityInfo = res;
  }

  openPlatform: RoadPlatformType[] = [];
  async getPlatforms(uid: number) {
    this.openPlatform = [];
    const res = await roadServices.getPlatforms(uid);
    this.setOpenPlatform(res.list);
    return Promise.resolve(res);
  }

  setOpenPlatform(list: RoadPlatformType[]) {
    this.openPlatform = list;
  }

  async updateStatus(params: UpdateStatusType) {
    const res = await roadServices.updateStatus(params);
    return Promise.resolve(res);
  }

  userPlatform: RoadUserPlatformType[] = [];
  async getUserPlatform(uid: number) {
    this.userPlatform = [];
    const res = await roadServices.getUserPlatforms(uid);
    this.setUserPlatform(res.list);
    return Promise.resolve(res);
  }

  setUserPlatform(list: RoadUserPlatformType[]) {
    this.userPlatform = list;
  }

  async updateUserPlatform(params: RoadUserPlatformType) {
    const res = await roadServices.updateUserPlatforms(params);
    return Promise.resolve(res);
  }

  // 更新isStopped爬虫状态
  async updateIsStopped(params: RoadPlatformIsStoppedParamsType) {
    const res = await roadServices.updateIsStopped(params);
    return Promise.resolve(res);
  }

  // platformModalVisible: boolean = false; // RoadManage 运营平台 Modal显示状态
  // platformModalData: RoadUserPlatformType | undefined = undefined; // RoadManage 运营平台 Modal回显数据

  // setPlatformModalVisible(platformModalVisible: boolean) {
  //   this.platformModalVisible = platformModalVisible;
  // }

  // setPlatformModalData(platformModalData: RoadUserPlatformType | undefined) {
  //   this.platformModalData = platformModalData;
  // }
}
