import memberServices, { CreateMemberParams, MemberListParams } from '@/services/memberServices';
import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';

export default class MemberStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'MemberStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  // 创建新会员权益
  async createNewMemberBenefit(params: CreateMemberParams) {
    const res = await memberServices.createNewMember(params)
    return Promise.resolve(res)
  }

  // 获取已开通会员信息
  async getMemberList(params: MemberListParams) {
    const res = await memberServices.getMemberList(params)
    return Promise.resolve(res)
  }
}