import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const DashboardLayout = lazy(() => import('@/layouts/DashboardLayout'));
const MainLayout = lazy(() => import('@/layouts/MainLayout'));

const WorkBench = lazy(() => import('@/pages/WorkBench'));
const AccountManage = lazy(() => import('@/pages/AccountManage'));
const AdobaroManage = lazy(() => import('@/pages/AdobaroManage'));
const AddCreator = lazy(() => import('@/pages/AccountManage/components/AddCreator'));
const RoadManage = lazy(() => import('@/pages/RoadManage'));
const IncomeManage = lazy(() => import('@/pages/IncomeManage'));
const ExperimentalCenter = lazy(() => import('@/pages/ExperimentalCenter'));
const NoticeManage = lazy(() => import('@/pages/NoticeManage'));
const TranslationManage = lazy(() => import('@/pages/TranslationService'));
const SRTTranslation = lazy(() => import('@/pages/TranslationService/SRTTranslation'));
const SendNotice = lazy(() => import('@/pages/NoticeManage/SendNotice'));
const NoticeRecord = lazy(() => import('@/pages/NoticeManage/NoticeRecord'));
const MemberBenefits = lazy(() => import('@/pages/MemberBenefits'));
const EnterpriseManagement = lazy(() => import('@/pages/EnterpriseManage'));
const EnterpriseDetail = lazy(() => import('@/pages/EnterpriseManage/EnterpriseDetail'));
const AuditConfig = lazy(() => import('@/pages/AuditConfig'));
// const DataAnalysis = lazy(() => import('@/pages/DataAnalysis'));
// const BusinessOrderManage = lazy(() => import('@/pages/BusinessOrderManage'));
// const PersonalCenter = lazy(() => import('@/pages/PersonalCenter'));
// const Feedback = lazy(() => import('@/pages/Feedback'));

const NotFound = lazy(() => import('@/pages/NotFound'));
const Login = lazy(() => import('@/pages/Login'));

const routes = [
  {
    path: '/app',
    element: <DashboardLayout />,
    children: [
      { path: '/app', element: <Navigate to="/app/adobaro-manage" /> },
      { path: 'work-bench', element: <WorkBench /> },
      // {
      //   path: 'account-manage',
      //   element: <AccountManage />,
      //   children: [
      //     { path: '/app/account-manage', element: <Navigate to="/app/account-manage/v1" /> },
      //   ],
      // },
      {
        path: 'account-manage',
        element: <AccountManage />,
        children: [{ path: 'add-creator', element: <AddCreator /> }],
      },
      {
        path: 'adobaro-manage',
        element: <AdobaroManage />,
      },
      { path: 'road-manage', element: <RoadManage /> },
      { path: 'income-manage', element: <IncomeManage /> },
      { path: 'experimental-center', element: <ExperimentalCenter /> },
      { path: 'member-benefits', element: <MemberBenefits /> },
      { path: 'audit-configuration', element: <AuditConfig /> },
      {
        path: 'enterprise-management',
        element: <EnterpriseManagement />,
        children: [{ path: 'enterprise-detail', element: <EnterpriseDetail /> }],
      },
      {
        path: 'notice-manage',
        element: <NoticeManage />,
        children: [
          {
            path: '/app/notice-manage',
            element: <Navigate to="/app/notice-manage/send-notice" />,
          },
          { path: 'send-notice', element: <SendNotice /> },
          { path: 'notice-record', element: <NoticeRecord /> },
        ],
      },
      {
        path: 'translation-service',
        element: <TranslationManage />,
        children: [
          {
            path: '/app/translation-service',
            element: <Navigate to="/app/translation-service/srt-translation" />,
          },
          { path: 'srt-translation', element: <SRTTranslation /> },
        ],
      },
      // { path: 'add-creator', element: <AddCreator /> },
      // { path: 'data-analysis', element: <DataAnalysis /> },
      // { path: 'business-order-manage', element: <BusinessOrderManage /> },
      // { path: 'personal-center', element: <PersonalCenter /> },
      // { path: 'feedback', element: <Feedback /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/account-manage" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '', element: <Navigate to="/app/account-manage" /> },
    ],
  },
];

export default routes;
