import { axios3, axios4 } from '@/utils/axios';
import {
  TaxManageListParamsType,
  RoadCreatorListType,
  TaxManageInfoType,
  UpdateWithdrawalsInfoParamsType,
  WithdrawalsListParamsType,
  WithdrawalsListType,
  WithDrawalsListDetailsType,
  // UserBankType,
  SearchCreatorListType,
  ExportSettlementInfoParams,
} from '@/stores/IncomeStore/types';

export interface IListResponse {
  nextPageToken: string;
  list: RoadCreatorListType[];
}

export interface ITaxManageListResponse {
  list: TaxManageInfoType[];
}

export interface IUpdateTaxManageListResponse {
  data: any;
  msg?: string;
  status?: number;
}

export interface IUpdateWithdrawalsInfoResponse {
  data: any;
  msg?: string;
  status?: string;
}

const incomeServices = {
  // 获取达人列表
  // getIncomeCreatorList: (status: string) =>
  //   axios3.get<IListResponse, IListResponse>('/studio/v1/users', {
  //     params: { status },
  //   }),

  // 获取扣税管理列表
  getTaxManageList: () =>
    axios3.get<ITaxManageListResponse, ITaxManageListResponse>('/studio/v1/wallet/tax_manage_info'),

  // 添加/修改扣税管理
  updateTaxManageList: (params: TaxManageListParamsType) =>
    axios3.post<IUpdateTaxManageListResponse, IUpdateTaxManageListResponse>(
      '/studio/v1/wallet/tax_manage_info',
      params
    ),

  // 获取提现列表
  getWithdrawalsList: (params: WithdrawalsListParamsType) =>
    axios3.get<WithdrawalsListType, WithdrawalsListType>('/studio/v1/wallet/withdrawals', {
      params,
    }),

  // 获取单个提现详情
  getWithdrawals: (id: number) =>
    axios3.get<WithDrawalsListDetailsType, WithDrawalsListDetailsType>(
      `/studio/v1/wallet/withdrawals/${id}`
    ),

  // 更新提现信息
  updateWithdrawalsInfo: (params: UpdateWithdrawalsInfoParamsType) =>
    axios3.post<IUpdateWithdrawalsInfoResponse, IUpdateWithdrawalsInfoResponse>(
      '/studio/v1/wallet/withdrawals',
      params
    ),

  // 获取用户银行卡信息
  // getUserBank: (uid: number) =>
  //   axios3.get<UserBankType, UserBankType>('/studio/v1/wallet/bank', { params: { uid } }),

  // 根据类型查询收益管理的达人列表
  getWithdrawalCreatorList: (status?: SearchCreatorListType) =>
    axios3.get<IListResponse, IListResponse>('/studio/v1/wallet/withdrawal_creators', {
      params: { status },
    }),

  // 搜索提现达人
  searchWithdrawalCreator: (search: string, status?: SearchCreatorListType) =>
    axios3.get<IListResponse, IListResponse>(
      `/studio/v1/wallet/withdrawal_creators/${search}?status=${status}`
    ),

  // 导出结算表
  exportSettlementList: (params: ExportSettlementInfoParams) =>
    axios4.post('/studio/v1/wallet/withdrawals:export_transfers', params),
};

export default incomeServices;
